import React from 'react'
import { graphql } from 'gatsby'
//import Link from 'gatsby-link';
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
//import PageTransition from 'gatsby-plugin-page-transitions';
import get from 'lodash/get'

//import MenuSidebar from '../components/MenuSidebar';
import SEO from '../components/SEO'
import Mailto from 'react-protected-mailto'
import ContactFormEs from '../components/ContactFormEs'

import icon32 from '../assets/images/favicon-32x32.png'
import social_image from '../assets/images/social-imagen.png'
import Template from '../components/layout'

class HomepageEs extends React.Component {
  render() {
    return (
      <Template location={get(this, 'props.location')}>
        <Helmet
          title="Ilse Valle Jones | Asesoría en Nutrición Bilingüe"
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${icon32}` },
            { rel: 'dns-prefetch', href: 'https://www.google.com' },
            { rel: 'dns-prefetch', href: 'https://stats.g.doubleclick.net' },
            { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
            {
              rel: 'preload',
              href: '/fonts/patua-one/patua-one-v7-latin-regular.subset.woff2',
              as: 'font',
              type: 'font/woff2',
              crossorigin: '',
            },
            {
              rel: 'alternate',
              href: 'https://www.ilsevalle.com/',
              hreflang: 'en',
            },
            {
              rel: 'alternate',
              href: 'https://www.ilsevalle.com/',
              hreflang: 'x-default',
            },
            {
              rel: 'preload',
              as: 'image',
              type: 'image/avif',
              imagesrcset:
                '/img/food-salad4.300.avif 300w, /img/food-salad4.300.avif 600w, /img/food-salad4.1200.avif 1200w, /img/food-salad4.1800.avif 1800w, /img/food-salad4.2127.avif 2127w',
              imagesizes: '(max-width: 1200px) 100vw, 1200px',
            },
            {
              rel: 'preload',
              href: '/static/fontello-804ebbbc572ec0d391346343be251588.woff',
              as: 'font',
              type: 'font/woff',
              crossorigin: 'anonymous',
            },
            {
              rel: 'preload',
              href: '/fonts/patua-one/patua-one-v7-latin-regular.subset.woff2',
              as: 'font',
              type: 'font/woff2',
              crossorigin: 'anonymous',
            },
          ]}
        >
          <meta name="author" content="Diego Valle-Jones" />
          <html lang="es" />
        </Helmet>
        <SEO
          socialTitle="Ilse Valle-Jones"
          seodesc="Ilse Valle es una nutrióloga que puede diseñarte un programa para bajar de peso y aprender a comer de manera saludable. Hay dietas para todos los gustos…"
          socialImage={social_image}
          twitter="@ilsevallejones"
        />
        <div id="headerwrap">
          <div
            className="vegas-overlay"
            style={{
              margin: '0px',
              padding: '0px',
              position: 'relative',
              left: '0px',
              top: '0px',
              width: '100%',
              height: '100%',
            }}
          >
            <picture>
              <source
                srcset="/img/food-salad4.300.avif 300w,
/img/food-salad4.300.avif 600w,
/img/food-salad4.1200.avif 1200w,
/img/food-salad4.1800.avif 1800w,
/img/food-salad4.2127.avif 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
                type="image/avif"
              ></source>
              <source
                type="image/webp"
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/d08b3/food-salad4.webp 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/10e4a/food-salad4.webp 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/30c24/food-salad4.webp 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/9a38c/food-salad4.webp 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/cf73c/food-salad4.webp 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
              ></source>
              <source
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/c536e/food-salad4.jpg 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/91764/food-salad4.jpg 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/7d962/food-salad4.jpg 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/d381e/food-salad4.jpg 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
              ></source>
              <img
                sizes="(max-width: 1200px) 100vw, 1200px"
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/c536e/food-salad4.jpg 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/91764/food-salad4.jpg 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/7d962/food-salad4.jpg 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/d381e/food-salad4.jpg 2127w"
                src="/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg"
                alt="avocado salad"
                title=""
                loading="eager"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  opacity: 1,
                  transition: 'none',
                }}
              ></img>
            </picture>

            <div className="overlay-points" />
            <header className="clearfix">
              <div
                style={{ background: 'rgba(0,0,0,0.5)', position: 'relative' }}
              >
                <h1
                  style={{
                    margin: '2em .3em 1em .3em',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  Asesoría Personalizada en <span>Nutrición</span> y Estilo de{' '}
                  <span>Vida Saludable</span>
                </h1>
              </div>
              <div className="container">
                <div className="row">
                  <div className="span4 offset2 header-list">
                    <h2 style={{ background: 'rgba(0,0,0,0.5)' }}>
                      <ul className="header-list">
                        <li>Empieza un programa de dieta o ejercicio</li>
                        <li>Mejora tus hábitos alimenticios</li>
                        <li>Plan de alimentación equilibrado</li>
                        <li>Información experta en nutrición</li>
                        <li>Obtén una vida saludable</li>
                      </ul>
                    </h2>
                  </div>

                  <div className="span4 text-center pagination-center">
                    <a
                      href="#contact"
                      className="btn btn-primary btn-large btn-contact"
                      style={{
                        fontFamily: 'Verdana, Geneva, sans-serif',
                        fontWeight: '700',
                      }}
                    >
                      Contáctame
                    </a>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>

        <div className="scrollblock">
          <section id="feature">
            <div className="container">
              <div className="row">
                <div className="span12">
                  <article>
                    <p
                      style={{
                        color: '#312a1e',
                        fontWeight: 'Bold',
                        textShadow: 'none',
                      }}
                    >
                      Puedo diseñarte un plan de alimentación y darte asesoría
                      personalizada para bajar de peso...
                    </p>
                    <br />
                    Algunos de los medios en los que he aparecido:
                    <br />
                    <Img
                      sizes={
                        this.props.data.oncetv.edges[0].node.childImageSharp
                          .fixed
                      }
                      alt="Canal Once"
                      title="Canal Once"
                      style={{
                        width: '130px',
                        height: '47px',
                        padding: '.5em',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      imgStyle={{
                        width: '130px',
                        height: '47px',
                        padding: '.5em',
                      }}
                    />
                    <Img
                      sizes={
                        this.props.data.nosotros.edges[0].node.childImageSharp
                          .fixed
                      }
                      alt="Periódico Nosotros"
                      title="Periódico Nosotros"
                      style={{
                        width: '130px',
                        height: '20px',
                        padding: '.5em',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      imgStyle={{
                        width: '130px',
                        height: '20px',
                        padding: '.5em',
                      }}
                    />
                    <Img
                      sizes={
                        this.props.data.cronica.edges[0].node.childImageSharp
                          .fixed
                      }
                      alt="La Crónica de Hoy"
                      title="La Crónica de Hoy"
                      style={{
                        width: '130px',
                        height: '29px',
                        padding: '.5em',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      imgStyle={{
                        width: '130px',
                        height: '29px',
                        padding: '.5em',
                      }}
                    />
                  </article>
                </div>
              </div>
            </div>
          </section>
        </div>
        <hr />
        <section id="home" className="single-page scrollblock">
          <div className="container">
            <div className="entry-content">
              <div className="align">
                {' '}
                <i className="icon-Nutritional sev_icon" />{' '}
              </div>
              <h3 className="indexh3">Bienvenidos</h3>
              <p>
                Mi nombre es Ilse Valle Jones y soy una{' '}
                <strong>nutrióloga psicoterapeuta</strong> profesional que vive
                en el área metropolitana de Philadelphia, puedo diseñarte un
                plan de alimentación y darte asesoría personalizada para bajar
                de peso, hacer ejercicio y tener un estilo de vida saludable.
              </p>

              <p>
                Si deseas tener una vida saludable, mejorar tus hábitos
                alimentarios, bajar de peso, hacer ejercicio y quieres un plan
                de alimentación equilibrado; o bien si padeces:
              </p>

              <ul className="pretty-list">
                <li>estreñimiento</li>
                <li>diabetes</li>
                <li>enfermedades de la tiroides</li>
                <li>enfermedades cardiovasculares</li>
                <li>desnutrición</li>
                <li>sobrepeso</li>
                <li>obesidad </li>
                <li>trastornos de la alimentación</li>
                <li>otras enfermedades</li>
              </ul>

              <p>
                con mi ayuda profesional, basada en tus necesidades
                nutrimentales, tu alimentación pronto será la adecuada.
              </p>
              <p>
                No importa en que etapa de la vida te encuentres —en la infancia
                o adolescencia, si eres adulto o de la tercera edad— tu
                alimentación y tu salud son lo más importante.
              </p>
              <hr />
            </div>
          </div>
        </section>
        <hr />
        <section id="consulting" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-cog-circled" />
            </div>

            <h3 className="indexh3">Consulta</h3>
            <div className="entry-content">
              <p>
                <b>¿Qué sucede durante una consulta?</b>
              </p>{' '}
              <ol className="pretty-list">
                <li> Lleno tu historia clínica</li>
                <li> Evalúo tu dieta actual</li>
                <li> Mido tu estatura</li>
                <li> Te peso y mido tu porcentaje de grasa</li>
                <li> Tomo medidas antropométricas</li>
                <li>
                  {' '}
                  Mido tu glucosa, colesterol y triglicéridos (por esta razón
                  requiero que vengas con por lo menos 5 horas de ayuno){' '}
                </li>
                <li> Tomo tu presión arterial</li>
                <li>
                  Identifico tus problemas y evalúo los motivos de la consulta
                </li>
                <li>
                  Calculo una dieta adecuada para ti, tomando en cuenta tus
                  gustos, actividades, horarios y lugares donde consumes tus
                  alimentos
                </li>
                <li>
                  Te doy un plan de alimentación individual que cumpla con tus
                  requisitos nutrimentales, que sea equilibrada y tenga variedad
                  de alimentos
                </li>
              </ol>
              <p>Si necesitas sesiones subsecuentes:</p>
              <ol className="pretty-list">
                <li>
                  Evalúo el progreso obtenido y doy un chequeo general:
                  <ol type="a" className="pretty-list">
                    <li>Tomo tu presión arterial</li>
                    <li>Tomo medidas antropométricas</li>
                    <li>
                      Mido tu glucosa, colesterol y triglicéridos (por esta
                      razón requiero que vengas con por lo menos 5 horas de
                      ayuno){' '}
                    </li>
                    <li>Te peso y mido tu porcentaje de grasa</li>
                  </ol>
                </li>
                <li>
                  Comparo resultados de consultas anteriores y evalúo las
                  mejorías
                </li>
                <li>
                  Reviso el plan que te di para ver si no tuviste problemas al
                  seguirlo{' '}
                </li>
                <li>Solucionamos las dificultadas que surjan </li>
                <li>
                  Irás aprendiendo qué alimentos son los más adecuados para tí y
                  cómo identificarlos{' '}
                </li>
              </ol>
              <p>
                <b>¿Cuánto dura una consulta?</b>
                <br />
                Primera Vez: Una hora
                <br />
                Consultas Subsecuentes: Media hora
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section id="testimonials" className="single-page hidden-phone">
          <div className="container">
            <div className="row">
              <div className="blockquote-wrapper">
                <blockquote className="mega">
                  <div className="span8">
                    <p className="alignright">
                      “Para tener buena salud: comer ligero, respirar profundo,
                      vivir moderadamente, cultivar la felicidad y llevar una
                      vida interesante”
                    </p>
                  </div>
                  <div className="span8">
                    <p className="cite">—William Londen</p>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section id="philosophy" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-pencil-circled" />
            </div>
            <h3 className="indexh3">Filosofía</h3>
            <div className="entry-content">
              <p>
                Llevar un estilo de vida saludable reduce los riesgos de padecer
                enfermedades. Tu salud tiene mucho que ver con tu estilo de
                vida: los alimentos que consumes, la cantidad de ejercicio que
                realizas, el estrés que manejas a diario y tener una mente sana
                que pueda lidiar con los problemas del día a día.{' '}
              </p>
              <p>
                Cada individuo es único y distinto a los demás, es por eso que
                tu alimentación debe ser personalizada y apropiada en cuanto a:
              </p>

              <ul className="pretty-list">
                <li>Edad</li>
                <li>Género</li>
                <li>Actividades</li>
                <li>Recursos Económicos</li>
                <li>Costumbres Personales</li>
              </ul>

              <p>La Nutrición Correcta debe ser:</p>

              <ul className="pretty-list">
                <li>Completa</li>
                <li>Suficiente</li>
                <li>Variada</li>
                <li>Higiénica</li>
                <li>Equilibrada</li>
              </ul>

              <p>
                ¡Mientras más buenos hábitos conozcas, más fácil será tener un
                estilo de vida saludable!
              </p>

              <p>
                Un link que te recomiendo es el{' '}
                <a href="https://web.archive.org/web/20090221035232/http://www.facmed.unam.mx/bmnd/index.php">
                  Diccionario de Especialidades Farmacéuticas
                </a>{' '}
                de la Facultad de Medicina de la UNAM. Si tienes una duda acerca
                de algún medicamento que estás consumiendo, búscalo en esta
                página y encontraras los efectos secundarios, cómo actúa, etc.
                Está muy facil de usar y está diseñada para pacientes.
              </p>

              <p>
                Visita mi <a href="/blog">bitácora (blog)</a> para aprender más
                de nutrición y mantenerte enterado de los últimos avances en el
                área de nutrición.
              </p>
            </div>
            <a
              href="/blog"
              className="btn btn-large"
              style={{ color: '#14112b' }}
            >
              Visita mi blog
            </a>{' '}
          </div>
        </section>
        <hr />
        <section id="about" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-torso" />
            </div>
            <h3 className="indexh3">Acerca de Ilse</h3>

            <div className="span8 offset2">
              <div itemScope="" itemType="http://schema.org/Person">
                <div className="teamalign">
                  <Img
                    sizes={
                      this.props.data.ilse.edges[0].node.childImageSharp.fluid
                    }
                    alt="Ilse Valle Jones"
                  />
                </div>
                <span id="spanh3">
                  <span
                    itemProp="name"
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                      display: 'table',
                    }}
                  >
                    Ilse Valle-Jones
                  </span>
                </span>
                <div className="job-position">
                  <span itemProp="jobTitle">nutritióloga</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="span10 offset1">
                <div className="featurette">
                  <h2 className="featurette-heading">
                    <span className="muted" style={{ fontFamily: 'Open Sans' }}>
                      acerca de Ilse...
                    </span>
                  </h2>
                  <p>
                    Soy nutrióloga psicoterapeuta. De la licenciatura en
                    Nutrición soy egresada de la Escuela de Dietetica y
                    Nutrición; la maestría en Psicoterapia fue en el Centro
                    Eleia. Además he tomado diplomados en Trastornos de la
                    Alimentación (Universidad Anahuac) y Enología (Universidad
                    Anahuac); tengo también bases de Química en Alimentos (UNAM)
                  </p>

                  <p>
                    He obtenido varios premios y reconocimientos en distintas
                    áreas:
                  </p>
                </div>

                <p> </p>
                <ul className="prizes">
                  <li>
                    Primer lugar en investigación básica en la Sociedad de
                    Nutriología A.C. (SNAC) con el proyecto:{' '}
                    <a href="//www.ilsevalle.com/blog/2005/11/efectos-de-la-administracion-de-vitaminas-antioxidantes/">
                      “Efectos de la Administración de Vitaminas Antioxidantes
                      en la reversión de la inhibición de la regeneración
                      hepática ocasionada por el consumo de etanol”
                    </a>{' '}
                    llevado a cabo en el Instituto Nacional de Ciencias Médicas
                    y Nutrición Salvador Zubirán (INCMNSZ).
                  </li>

                  <li>
                    Segundo lugar en investigación básica en el 17 Congreso
                    Nacional AMMFEN con el proyecto: “Efectos de la
                    Administración de Vitaminas Antioxidantes en la reversión de
                    la inhibición de la regeneración hepática ocasionada por el
                    consumo de etanol” llevado a cabo en Mérida, Yucatán{' '}
                  </li>

                  <li>
                    Reconocimiento como expositora en trabajos libres con el
                    tema:{' '}
                    <a href="//www.ilsevalle.com/blog/2005/11/efectos-de-la-administracion-de-vitaminas-antioxidantes-en-la-reversion-de-la-inhibicion-de-la-regeneracion-hepatica-ocasionada-por-el-consumo-de-etanol-3/">
                      “Efectos de la Administración de Vitaminas Antioxidantes
                      en la reversión de la inhibición de la regeneración
                      hepática ocasionada por el consumo de etanol”
                    </a>{' '}
                    en las Jornadas Médicas del Centro Médico Nacional 20 de
                    Noviembre y en el Congreso Nacional Anual de Bioquímica.
                  </li>

                  <li>
                    Reconocimiento como expositora en la 1ª Semana de Ciencia,
                    Tecnología y Arte de la Escuela de Dietética y Nutrición con
                    el tema: “Fermentación de manzanas y aislamiento de
                    levaduras para la elaboración de sidra” (junto con Maricela
                    Martínez Cruz)
                  </li>

                  <li>
                    Premio económico por buenos méritos otorgado por Bestfoods
                    Educational Foundation
                  </li>

                  <li>Participación en torneos deportivos</li>

                  <li>
                    Reconocimiento por la dedicación y actitud en el trabajo
                    diario. Angloamericano.{' '}
                  </li>
                </ul>
                <p />

                <p />

                <p />
                <div className="featurette">
                  <p>
                    Mis artículos periodísticos han aperecido en el periódico{' '}
                    <i>Nosotros</i> del ISSSTE y en la <i>Crónica de Hoy</i>.
                  </p>
                </div>

                <ul className="prizes">
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/">
                      Ventajas de la Dieta Mediterranea
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/">
                      Soya, Ventajas de uso y preparación
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/migrana-su-relacion-con-la-alimentacion-3/">
                      Migraña y Alimentación
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/">
                      Ver Todos los Artículos
                    </a>
                  </li>
                </ul>

                <hr className="featurette-divider" />
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-mail2" />
            </div>
            <h3 className="indexh3">Contacto</h3>
            <h2>
              <p>
                ¿Tienes algún comentario? ¿Tienes un proyecto en mente? Me
                puedes contactar en:
              </p>
              <p>
                <Mailto email="ilse@ilsevalle.com" obfuscatedHref="" />
              </p>
              <p> o si prefieren pueden usar el formulario</p>
            </h2>
            <div className="row">
              <div className="span12">
                <div className="cform" id="theme-form">
                  <div id="note" />
                  <ContactFormEs />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Template>
    )
  }
}

export default HomepageEs

export const pageQuery = graphql`
  query IndexQuery2 {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    imageHead: allFile(
      filter: { relativePath: { regex: "/food-salad4.jpg/" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1200, quality: 45) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    ilse: allFile(filter: { relativePath: { regex: "/ilse-valle-jones.jpg/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    nosotros: allFile(filter: { relativePath: { regex: "/nosotros.png/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 130, height: 20) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    oncetv: allFile(filter: { relativePath: { regex: "/oncetv.png/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 130, height: 47) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    cronica: allFile(filter: { relativePath: { regex: "/cronica.png/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 130, height: 29) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
